import React from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import ReactGA from "react-ga"
const Header = () => {
  return (
    <header id="header">
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
      ></link>

      <Navbar bg="dark" expand="lg" variant="dark">
        <Navbar.Toggle aria-controls="basic-navbar-nav mr-auto" />
        <Navbar.Collapse id="basic-navbar-nav" bg="dark" variant="dark">
          <Nav className="mr-auto">
            <a
              className="nav-link"
              title="Contact TIH"
              href="mailto:info@thisishappening.com"
              onClick={() => {
                ReactGA.ga("send", "event", "Navigations", "Contact Us")
              }}
            >
              Contact Us
            </a>
            <Link
              className="nav-link"
              to="/faq"
              state={{
                modal: true,
              }}
              title="About This is Happening"
              onClick={() => {
                ReactGA.ga("send", "event", "Navigations", "About")
              }}
            >
              About
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="socialicons">
        <ul>
          <li>
            <a
              onClick={() => {
                ReactGA.ga("send", "event", "Navigations", "Twitter")
              }}
              href="https://twitter.com/TIHOFFICIAL1"
              title="TIH Twitter"
            >
              <i className="fab fa-twitter icon"></i>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                ReactGA.ga("send", "event", "Navigations", "Facebook")
              }}
              title="TIH Facebook"
              href="https://www.facebook.com/ThisIsHappeningOfficial/"
            >
              <i className="fab fa-facebook-f icon"></i>{" "}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                ReactGA.ga("send", "event", "Navigations", "Instagram")
              }}
              title="@TIH Instagram"
              href="https://www.instagram.com/ThisIsHappeningOfficial/"
            >
              <i className="fab fa-instagram icon"></i>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                ReactGA.ga("send", "event", "Navigations", "Youtube")
              }}
              title="TIH Youtube"
              href="https://www.youtube.com/channel/UCsVXjNRWJMyXViNLM2pyMfg"
            >
              <i className="fab fa-youtube icon"></i>
            </a>
          </li>
        </ul>
      </div>

      <div>
        <img
          src="/header.png"
          alt="Jukin Video - This is happening"
          className="d-none d-sm-none d-md-block"
        />
        <img
          src="/header_mobile.jpg"
          alt="Jukin Video - This is happening"
          className="d-md-none"
        />
      </div>
    </header>
  )
}
export default Header
