import React from "react"
import { Link } from "gatsby"
import Header from "./Header"
import "../sass/styles.scss"
import ReactGA from "react-ga"

class Layout extends React.Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  render() {
    const { children } = this.props

    return (
      <div>
        <Header />
        <main>{children}</main>
        <footer>
          <div className="bg">
            <div className="text-center">
              <img
                src="/logo.png"
                className="logo"
                alt="this is happening logo"
              />
            </div>
          </div>
          <div className="text-center copyright">
            <p>
              Copyright Jukin Media Inc. {new Date().getFullYear()}. All rights
              reserved |{" "}
              <Link
                to="/terms"
                onClick={() => {
                  ReactGA.ga("send", "event", "Footer", "Terms")
                }}
                state={{
                  modal: true,
                }}
              >
                Terms
              </Link>{" "}
              |{" "}
              <Link
                to="/privacypolicy"
                state={{
                  modal: true,
                }}
                onClick={() => {
                  ReactGA.ga("send", "event", "Footer", "Privacy Policy")
                }}
              >
                Privacy Policy
              </Link>{" "}
              |{" "}
              <a
                onClick={() => {
                  ReactGA.ga("send", "event", "Footer", "Contact Us")
                }}
                title="Contact TIH"
                href="mailto:info@thisishappening.com"
              >
                Contact Us
              </a>
            </p>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
